<template>
  <div>
    <!-- 公共头部 -->
    <Header></Header>

    <div class="section1">
      <div class="item" :class="[ cIndex == 0 ? 'active' : '' ]" @click="changeCindex(0)">网上上传</div>
      <div class="item" :class="[ cIndex == 1 ? 'active' : '' ]" @click="changeCindex(1)">现场提交</div>
      <div class="item" :class="[ cIndex == 2 ? 'active' : '' ]" @click="changeCindex(2)">快递我校</div>
    </div>

    <div class="section2" v-show="cIndex == 0">
      <div class="box">
        <div class="form-box">

          <div class="item" v-for="(item,index) in materialCustom" @click="houseUpload(index)" :key="index">
            <input ref="houseTypeLoad" style="display: none;" type="file" accept="image/jpeg, image/jpg, image/png" @change="upLoadHouse(index)">
            <div class="topbox">
              <div class="title">{{item.custom}}</div>
              <!-- <div>{{item.explain}}</div> -->

              <div class="right-box right-icon">
                <img class="img" :src="[ item.value ? item.showImg : item.img]" />
              </div>
            </div>

            <div class="explain">
              <div class="explain_icon">
                <img src="@/assets/images/icon22.png" alt="">
              </div>
              <div class="explain_text">
                <span class="explain_cont">{{item.explain}}</span>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div v-if="materialCustom.length && materialCustom[0].status == -1" class="tip">审核驳回：{{materialCustom[0].case}}</div>

      <div class="submit-box" v-if="materialCustom.length">
        <div class="submit" @click="OpenMask">提交审核</div>
      </div>
    </div>

    <div class="section3" v-show="cIndex == 1 || cIndex == 2">
      <div class="list">
        <div class="item">
          <div class="left-box">
            <div class="pic">
              <img class="icon" src="@/assets/images/icon52.png" />
            </div>
            <div class="title">联系人</div>
          </div>
          <div class="right-box">{{sceneInfo.name}}</div>
        </div>
        <div class="item">
          <div class="left-box">
            <div class="pic">
              <img class="icon" src="@/assets/images/icon53.png" />
            </div>
            <div class="title">{{cIndex == 1 ? '咨询电话' : '手机'}}</div>
          </div>
          <div class="right-box">
            <a :href="'tel:' + sceneInfo.tel" style="color: #121e5f;text-decoration: underline;" v-if="cIndex == 1">{{sceneInfo.tel}}</a>
            <a :href="'tel:' + sceneInfo.phone" style="color: #121e5f;text-decoration: underline;" v-if="cIndex == 2">{{sceneInfo.phone}}</a>
          </div>
        </div>
        <div class="item">
          <div class="left-box">
            <div class="pic">
              <img class="icon" src="@/assets/images/icon54.png" />
            </div>
            <div class="title">地址</div>
          </div>
          <div class="right-box">{{sceneInfo.address}}</div>
        </div>
      </div>
      <div class="tip">{{tip}}</div>
    </div>

    <!-- 暂未用到 -->
    <div class="upload-box" style="display: none;">
      <div class="box1">
        <div class="pic">
          <img class="img" src="@/assets/images/icon51.png" />
        </div>
        <div class="title">身份证正面(国徽)</div>
      </div>
      <div class="box2">
        <div class="tip">请您上传清晰、无污物、完整证件原件照片或彩色扫描件，图片文件后缀支持jpg/jpeg，上传图片大小建议在500kb以下。</div>
        <div class="submit-box">
          <div class="submit">保存</div>
        </div>
      </div>
    </div>

    <div class="mask" v-if="showMask"></div>
    <div class="alert-box" :class="[ showMask ? 'active' : '' ]">
      <h2 class="cate-title">提交审核确认</h2>
      <div class="desc">{{$store.state.userInfo.name}}你好。您所上传有关{{$route.query.title}}的资料如下，请确认无误后，点击下方确认按钮开始上传。</div>
      <ul class="list">
        <li class="item" v-for="(item,index) in materialCustom" :key="index">
          <h2 class="title">{{item.custom}}</h2>
          <div class="pic-box">
            <img class="img" :src="item.showImg" />
          </div>
        </li>
      </ul>
      <div class="tip">提示：资料提交后无法修改，请仔细确认！</div>
      <div class="button_group">
        <div class="btn" @click="showMask = false">取消</div>
        <div class="btn btn1" @click="submit">确认提交</div>
      </div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '资料上传',
				materialCustom: [],		// 网上上传字段列表
				sceneInfo: '',
				showMask: false,
				cIndex: 0,
				tip: '注意事项：请提前告知所报内容！'
			}
		},
		computed: {
			uid() {
				return this.$store.state.uid;
			}
		},
		activated(){
			this.getUserInfo();
		},
		deactivated() {
			this.materialCustom = [];
			this.sceneInfo = '';
			this.showMask = false;
			this.cIndex = 0;
		},
		methods: {
			async upLoadHouse(index) {
				var uid = this.uid;
				var file = this.$refs.houseTypeLoad[index].files[0];
				if (!file) {
					return false;
				}
				var formData = new FormData();
				formData.append("file",file);
				formData.append('uid',uid);
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/other/uploadFile',formData);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('资料上传 上传文件：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var materialCustom = this.materialCustom;
					this.materialCustom[index]['value'] = res.data;
					this.materialCustom[index]['showImg'] = this.$store.state.http + res.data;
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			houseUpload(index) {
				this.$refs.houseTypeLoad[index].click();
			},
			// 获取上传字段
			async getMaterialCustom() {
				var student_id = this.$route.query.id;
				var uid = this.uid;
				var data = {
					uid, student_id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/study/materialCustom', { params: data });
			
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('资料上传 上传字段：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var materialCustom = res.data;
					for(var i = 0; i < materialCustom.length; i++){
						if(materialCustom[i]['path']){
							if(materialCustom[i]['path'].substring(0,4) != 'http'){
								materialCustom[i]['showImg'] = this.$store.state.http + materialCustom[i]['path'];
							} else {
								materialCustom[i]['showImg'] = materialCustom[i]['path'];
							}
							materialCustom[i]['value'] = materialCustom[i]['path'];
						} else if(materialCustom[i]['img']){
							if(materialCustom[i]['img'].substring(0,4) != 'http'){
								materialCustom[i]['img'] = this.$store.state.http + materialCustom[i]['img'];
							} else {
								materialCustom[i]['img'] = materialCustom[i]['img'];
							}
							materialCustom[i]['value'] = '';
							materialCustom[i]['showImg'] = '';
						}
					}
					this.materialCustom = materialCustom;
				} else {
					this.materialCustom = [];
					this.$toast.warning('未获取到项目上传字段！');
				}
			},
			// 获取现场和快递信息
			async getScene() {
				var student_id = this.$route.query.id;
				var uid = this.uid;
				var data = {
					uid, student_id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/scene', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('资料上传 现场和快递信息：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.sceneInfo = res.data;
				} else {
					this.sceneInfo = '';
					this.$toast.warning(res.data);
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
						wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
                return;
					}
					wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
              
				} else {
					this.getMaterialCustom();
					this.getScene();
				}
			},
			async submit() {
				var materialCustom = this.materialCustom;
				var data = {};
				for(var i = 0; i < materialCustom.length; i++){
					if(!materialCustom[i]['value']){
						this.$messageBox.alert('请上传' + materialCustom[i]['custom']);
						this.showMask = false;
						return false;
					}
					data[materialCustom[i]['name']] = materialCustom[i]['value'];
				}
				data['uid'] = this.$store.state.userInfo.id;
				data['student_id'] = this.$route.query.id;
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/study/materialUpload',data);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('资料上传 提交审核：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.$messageBox.alert(res.data, '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.go(-1);
						}
					});
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			OpenMask() {
				var materialCustom = this.materialCustom;
				for(var i = 0; i < materialCustom.length; i++){
					if(!materialCustom[i]['value']){
						this.$messageBox.alert('请上传' + materialCustom[i]['custom']);
						return false;
					}
				}
				this.showMask = true;
			},
			changeCindex(cIndex) {
				this.cIndex = cIndex;
				if(cIndex == 1){
					this.tip = '注意事项：请提前告知所报内容！';
				} else if(cIndex == 2){
					this.tip = '注意事项：快递费用自理，快递途中遗失与我校无关';
				}
			}
		},
	};
</script>

<style scoped>
.section1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section1 .item {
  flex: 1;
  font-size: 0.28rem;
  color: #969897;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8rem;
}
.section1 .item.active {
  font-size: 0.32rem;
  color: #ff6000;
  position: relative;
}
.section1 .item.active::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 0.48rem;
  height: 0.04rem;
  background: #ff6000;
  border-radius: 0.02rem;
  bottom: 0.08rem;
  left: 50%;
  transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}

.section2 {
  padding-top: 0.3rem;
  padding-bottom: 0.6rem;
}
.section2 .box {
  margin-top: 0.3rem;
}
.section2 .box:first-child {
  margin-top: 0;
}
.section2 .box .form-box {
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section2 .box .form-box .item {
  padding: 0.1rem 0.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 2.1rem;
  border-top: 0.02rem solid #e1e1e1;
}
.section2 .box .form-box .item:nth-child(n + 3) {
  height: 1.7rem;
}
.section2 .box .form-box .item:first-child {
  border-top: none;
}
.section2 .box .form-box .item .title {
  font-size: 0.28rem;
  color: #333333;
}
.section2 .box .form-box .item .right-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 3rem;
  padding-right: 0.24rem;
}
.section2 .box .form-box .item .right-box.right-icon {
  background: url(../../../assets/images/icon23.png) no-repeat right center;
  background-size: 0.09rem 0.17rem;
}
.section2 .box .form-box .item .right-box .img {
  height: 0.8rem;
  background: #dbdbdb;
  padding: 0.06rem;
}
.explain {
  text-align: start;
  width: 100%;
  padding: 0.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.explain .explain_icon {
  flex: 0.4;
}
.explain_text {
  flex: 8;
  font-size: 0.9em;
  padding: 0 5%;
}
.section2 .tip {
  font-size: 0.28rem;
  color: #ff6000;
  line-height: 0.42rem;
  padding-left: 0.44rem;
  background: url(../../../assets/images/icon22.png) no-repeat left top 0.1rem;
  background-size: 0.34rem 0.34rem;
  margin: 0.3rem;
}
.section2 .submit-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.6rem;
}
.section2 .submit-box .submit {
  width: 5.8rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121e5f;
  font-size: 0.3rem;
  color: #ffffff;
  border-radius: 0.5rem;
}

.upload-box {
  width: 100%;
  height: calc(100vh - 2rem);
  position: fixed;
  top: 1rem;
  left: 0;
  background: #f6f6f6;
}
.upload-box .box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.upload-box .box1 .pic {
  width: 5rem;
  height: 3rem;
  background: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-box .box1 .pic .img {
  max-width: 3rem;
}
.upload-box .box1 .title {
  font-size: 0.26rem;
  color: #333333;
  margin-top: 0.6rem;
}
.upload-box .box2 {
  padding: 0.5rem 0.3rem;
}
.upload-box .box2 .tip {
  font-size: 0.28rem;
  color: #ff6000;
  line-height: 0.42rem;
  padding-left: 0.44rem;
  background: url(../../../assets/images/icon22.png) no-repeat left top 0.1rem;
  background-size: 0.34rem 0.34rem;
}
.upload-box .box2 .submit-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.upload-box .box2 .submit-box .submit {
  width: 5.8rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121e5f;
  font-size: 0.3rem;
  color: #ffffff;
  border-radius: 0.5rem;
}

.alert-box {
  position: fixed;
  width: 7rem;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  border-radius: 0.14rem;
  padding: 0.4rem 0.5rem;
  transition: all 0.3s;
  max-height: 75%;
  overflow-y: scroll;
}
.alert-box.active {
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.alert-box .cate-title {
  font-size: 0.32rem;
  color: #333333;
  text-align: center;
  margin-bottom: 0.3rem;
}
.alert-box .desc {
  font-size: 0.26rem;
  color: #333333;
  line-height: 0.42rem;
  text-indent: 0.52rem;
}
.alert-box .list {
  margin-top: 0.4rem;
}
.alert-box .list .item {
  margin-top: 0.3rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.alert-box .list .item:first-child {
  margin-top: 0;
}
.alert-box .list .item .title {
  font-size: 0.26rem;
  color: #333333;
  margin-top: 0.2rem;
}
.alert-box .list .item .pic-box {
  width: 2.2rem;
  height: 1.4rem;
  background: #f3f3f3;
  border: 0.02rem solid #c1c1c1;
  border-radius: 0.12rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-box .list .item .pic-box .img {
  max-height: 100%;
}
.alert-box .tip {
  margin-top: 0.3rem;
  font-size: 0.26rem;
  color: #ff6000;
}
.alert-box .button_group {
  text-align: center;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alert-box .button_group .btn {
  width: 2rem;
  height: 0.7rem;
  background: #d4d4d4;
  font-size: 0.32rem;
  color: #ffffff;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-box .button_group .btn1 {
  background: #121e5f;
}

.section3 {
  margin-top: 0.3rem;
}
.section3 .list {
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section3 .item {
  overflow: hidden;
  padding: 0.3rem;
  border-top: 0.02rem solid #e7e7e7;
}
.section3 .item:first-child {
  border-top: none;
}
.section3 .item .left-box {
  display: flex;
  align-items: center;
  float: left;
  line-height: 0.4rem;
}
.section3 .item .left-box .pic {
  width: 0.34rem;
  height: 0.34rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section3 .item .left-box .pic .icon {
}
.section3 .item .left-box .title {
  font-size: 0.28rem;
  color: #333333;
  margin-left: 0.2rem;
}
.section3 .item .right-box {
  float: right;
  font-size: 0.26rem;
  color: #969897;
  max-width: 4.2rem;
  line-height: 0.4rem;
}
.section3 .tip {
  margin: 0.5rem 0.3rem 0;
  padding-left: 0.55rem;
  font-size: 0.28rem;
  color: #ff6000;
  background: url(../../../assets/images/icon22.png) no-repeat left center;
  background-size: 0.34rem 0.35rem;
  line-height: 0.4rem;
}
.topbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>